/* eslint-disable import/no-named-as-default */
/* eslint-disable one-var */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import '../styles/globals.css';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { ThemeProvider } from 'next-themes';
import { getCookies, setCookie, getCookie } from 'cookies-next';
import 'normalize.css';
import 'lib/styles/_base.scss';
import sendEvent from 'utils/experimentation';
import { ViewportProvider } from 'utils/context/ViewportContextProvider';
import GlobalContext from 'utils/context/GlobalContextProvider';
import Script from 'next/script';
import {
    GOOGLE_TAG_MANAGER_CONTAINER,
    COUNTRY_COOKIE_NAME,
    COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
    COOKIE_POLICY,
    COOKIE_DOMAIN,
    ENVIRONMENT,
    UID_COOKIE,
} from 'utils/constants';
import reportAccessibility from 'utils/reportAccessibility.ts';
import { cBoolean, getDeviceType } from '@viome/vnxt-nucleus';
import getUserData from 'utils/user/getUserData';
import getUserSubscriptions from 'utils/user/getUserSubscriptions';
import getUserCountry from 'utils/user/getUserCountry';
import { fontsClassNames } from 'lib/fonts/mapping';
import { Toaster } from 'sonner';
import { StatsigProvider } from '@statsig/react-bindings';
import statsigClient from 'utils/statsig';

const App = (props) => {
    const [hasMounted, setHasMounted] = useState(false),
        {
            Component,
            pageProps,
            clientCountry,
            clientCountryIsEUorGB,
            clientIp,
            clientDevice,
            userID,
            experiments,
        } = props,
        // Need to attempt to grab cookies on the client side as well.
        // Because the cookie is created on the client side, the server won't know
        // it exist until a full refresh.
        clientCookies = getCookies(),
        acceptedCookiePolicy = clientCookies[COOKIE_POLICY.NAME] === 'true',
        clientUserID = clientCookies[UID_COOKIE],
        clientCountryCookie = clientCookies[COUNTRY_COOKIE_NAME],
        clientCountryIsEUorGBCookie =
            clientCookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        [user, setUserData] = useState(false),
        [userCountry, setUserCountry] = useState(
            // Check both server and client side cookie if country data exist
            clientCountry || clientCountryCookie,
        ),
        userCountryIsEUorGBProps =
            cBoolean(clientCountryIsEUorGB) ||
            cBoolean(clientCountryIsEUorGBCookie),
        [userCountryIsEUorGB, setUserCountryIsEUorGB] = useState(
            userCountryIsEUorGBProps,
        ),
        [global, updateGlobal] = useState({
            variant: pageProps.variant || 'Control',
            userID: userID || clientUserID,
            upsell: pageProps.upsell || null,
            experiments: experiments ?? [],
            update: (data) => {
                updateGlobal({ ...global, ...data });
            },
        });

    useEffect(() => {
        const experiments = [],
            // VNXT 1014 - https://viomeinc.atlassian.net/browse/VNXT-1008
            handleUserData = async () => {
                const userData = await getUserData();
                if (userData.error) return null;

                const userSubscriptions = await getUserSubscriptions(
                        userData?.email,
                    ),
                    hasSubscription =
                        userSubscriptions?.has_active_subscriptions ||
                        userSubscriptions?.status === 'ALL_CANCELLED';
                setUserData({ ...userData, hasSubscription });
                if (userData) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        user: userData,
                        event: 'logged_user',
                    });
                }
                return true;
            },
            // VNXT 1029 - https://viomeinc.atlassian.net/browse/VNXT-1029
            // Call Abstract api's and set a cookie if country data isn't defined

            handleUserCountry = async () => {
                let country = userCountry,
                    countryIsEUorGB = userCountryIsEUorGB;

                if (
                    !country ||
                    country === '-' ||
                    !clientCountryIsEUorGBCookie
                ) {
                    const { countryPhoneCode } = user,
                        countryResponse = await getUserCountry(clientIp);
                    if (countryPhoneCode) {
                        country = countryPhoneCode;
                    }
                    if (!countryResponse?.error) {
                        country = countryResponse?.country_code;
                        countryIsEUorGB =
                            countryResponse?.country_is_eu ||
                            countryResponse?.country_code === 'GB';
                    }
                    if (country) {
                        setUserCountry(country);
                    }
                    setUserCountryIsEUorGB(countryIsEUorGB);
                }

                // Sets a cookie if cookie doesn't already exist and country is vaild
                if (!clientCountryCookie && country) {
                    setCookie(COUNTRY_COOKIE_NAME, `${country}`, {
                        domain: COOKIE_DOMAIN,
                        maxAge: 365 * 86400, // One Year
                        path: '/',
                    });
                }

                // Sets a cookie for country is EU if cookie doesn't already exist
                if (!clientCountryIsEUorGBCookie) {
                    setCookie(
                        COUNTRY_IS_EU_OR_GB_COOKIE_NAME,
                        cBoolean(clientCountryIsEUorGB) ||
                            cBoolean(clientCountryIsEUorGBCookie) ||
                            cBoolean(countryIsEUorGB),
                        {
                            domain: COOKIE_DOMAIN,
                            maxAge: 365 * 86400, // One Year
                            path: '/',
                        },
                    );
                }

                return country;
            };

        handleUserData();
        handleUserCountry();
    }, []);

    useEffect(() => {
        const globalUpdates = {};

        if (global.variant !== pageProps.variant) {
            globalUpdates.variant = pageProps.variant || 'Control';
        }
        if (global.upsell !== pageProps.upsell) {
            globalUpdates.upsell = pageProps.upsell || null;
        }
        if (
            pageProps.experiments &&
            global.experiments !== pageProps.experiments
        ) {
            globalUpdates.experiments = [
                ...new Set([...global.experiments, ...pageProps.experiments]),
            ];
        } else if (!pageProps.experiments) {
            globalUpdates.experiments = [];
        }
        if (Object.entries(globalUpdates).length > 0) {
            global.update(globalUpdates);
        }
    }, [pageProps]);

    return (
        <ThemeProvider forcedTheme="light">
            <StatsigProvider client={statsigClient}>
                <ViewportProvider clientDevice={clientDevice}>
                    <Head>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
                        />
                    </Head>
                    <GlobalContext.Provider value={global}>
                        <div className={fontsClassNames}>
                            <Component {...pageProps} />
                        </div>
                    </GlobalContext.Provider>
                    {/* Google Tag Manager */}
                    <Script
                        id="google-tag-manager"
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}

                        gtag('consent', 'default', {
                            // Always grant for non EU/UK
                            'ad_storage': '${
                                acceptedCookiePolicy || !userCountryIsEUorGB
                                    ? 'granted'
                                    : 'denied'
                            }',
                            'analytics_storage': 'granted',
                            'functionality_storage': 'granted',
                            'personalization_storage': 'granted',
                            'security_storage': 'granted',
                        });

                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_CONTAINER}');
                    `,
                        }}
                    />
                    {/* End Google Tag Manager */}
                </ViewportProvider>
                <Toaster richColors closeButton />
            </StatsigProvider>
        </ThemeProvider>
    );
};

App.getInitialProps = async ({ ctx }) => {
    // Get the client IP address
    const { req, res } = ctx,
        cookies = getCookies({ req, res }),
        countryCookie = cookies[COUNTRY_COOKIE_NAME],
        countryIsEUorGBCookie = cookies[COUNTRY_IS_EU_OR_GB_COOKIE_NAME],
        extraProps = {},
        userID = req?.headers.viome_uid || cookies[UID_COOKIE],
        experiments =
            req?.headers.experiments && req.headers.experiments.split(',');

    if (req && req.headers['x-forwarded-for']) {
        const forwarded = req.headers['x-forwarded-for'],
            ip = forwarded
                ? forwarded.split(',')[0]
                : req.connection?.remoteAddress,
            clientIp = ip !== '::1' ? ip : '1.1.1.1';

        extraProps.clientIp = clientIp;
        extraProps.clientDevice = getDeviceType(req.headers['user-agent']);
    }

    extraProps.userID = userID;
    extraProps.clientCountry = countryCookie;
    extraProps.clientCountryIsEUorGB = countryIsEUorGBCookie || false;
    extraProps.experiments = experiments;

    return extraProps;
};

export default App;
