const {
    NEXT_PUBLIC_API_DOMAIN: apiDomain,
    NEXT_PUBLIC_APP_URL: appUrl,
    NEXT_PUBLIC_VIOME_APP_URL: viomeAppUrl,
} = process.env;

export const ENVIRONMENT =
    process.env.NEXT_PUBLIC_VERCEL_APP_ENV || process.env.NODE_ENV;

export const UID_COOKIE = 'viome_uid';
export const PLATFORM_COOKIE = 'viome_platform';

export const CURRENCY = 'USD';
export const CURRENCY_SHORT = 'US';

export const APP_HOST =
    ENVIRONMENT === 'preview'
        ? 'https://cancerdetect-test.viome.com'
        : process.env.APP_HOST;

export const APP_NAME = 'viome-cd';

export const GOOGLE_SCHEMA_BRAND = 'Viome';

export const SUPPORT_URL = 'https://support.viome.com';

export const VIOME_COM_URL = 'https://www.viome.com';

export const CTA_TEXT = {
    DEFAULT: 'Add to cart',
    SUBSCRIPTION: 'Get started',
};

export const COOKIE_POLICY = {
    AGE: 30 * 24 * 60 * 60, // 30 Days
    BUTTON_ACCEPT_TEXT: 'Accept',
    COPY: 'Viome, and the companies we work with, use cookies and other technologies to collect data to improve your experience on our site, analyze site usage, and facilitate advertising. By continuing to browse this site, you agree to our ',
    EU_COPY:
        'We use cookies to offer you a better user experience and analyze site traffic. By continuing to use this website, you consent to the use of cookies in accordance with our ',
    NAME: 'cookie_consent_status',
    BUTTON_REJECT_TEXT: 'Reject',
};

export const COOKIE_DOMAIN = 'viome.com'

export const COUNTRY_COOKIE_NAME = 'viome_country_code_v2';

export const COUNTRY_IS_EU_OR_GB_COOKIE_NAME = 'viome_country_is_eu_or_gb_v2';

export const GOOGLE_ANALYTICS = {
    SCOPE: 'https://www.googleapis.com/auth/analytics.readonly',
    MARKETING_VIEW_ID: 173388273,
};

export const GOOGLE_TAG_MANAGER_CONTAINER =
    ENVIRONMENT === 'production' ? 'GTM-WMJPZ4P' : 'GTM-N9SRJ6X';

export const SHOPIFY = {
    STAGING_HOST: 'staging-buy',
    STAGING_ACCESS_TOKEN: '4eb16d5f44299895a7d53703900a96b8',
    STAGING_DOMAIN: 'viome-integration',
    PRODUCTION_HOST: 'buy',
    PRODUCTION_ACCESS_TOKEN: '2eb824a6ba5b5d62168523f127bcf46d',
    PRODUCTION_DOMAIN: 'viome1',
};

export const SHOPIFY_HOST_SUBDOMAIN =
    ENVIRONMENT === 'production' ? 'buy' : 'staging-buy';

export const CONTENTFUL = {
    ENVIRONMENT: 'master',
    LOCALE: 'en-US',
};

export const KLAVIYO = {
    API_KEY: 'pk_6d73ee44077d00210803be129ae95622cb',
    EMAIL_SUBSCRIPTION_LIST_URL: (sourceId) =>
        `https://manage.kmail-lists.com/ajax/subscriptions/subscribe?g=${sourceId}`,
    SMS_LEADS_SUBSCRIPTION_URL: (sourceId) =>
        `https://a.klaviyo.com/api/v2/list/${sourceId}/subscribe`,
    EMBED_FOOTER_SIGNUP_FORM_ID: 'UsRwXb',
    EMBED_MODAL_SIGNUP_FORM_ID: 'TeEuX6',
    EMBED_BLOG_SIGNUP_FORM_ID: 'WiQzT3',
    EMBED_CD_SIGNUP_FORM_ID: 'YtzBmY',
    EXIT_INTENT_SIGNUP: 'EXIT_INTENT_SIGNUP',
    COMPANY_ID: 'R7TEm4',
};

export const CLIENT_REDIRECTS = {
    VIOME_NL: 'https://www.viome.nl',
};

export const BLOG_ARTICLES_STEP_LIMIT = 12;
export const BLOG_INDEX_LIMIT = 3;
export const BLOG_INDEX_BODY_LIMIT = 6;

export const VIEWPORT_WIDTHS = {
    MOBILE: 0,
    TABLET: 768,
    LARGE: 1024,
    DESKTOP: 1200,
    XL: 1440,
};

export const VIEWPORT_LABELS = {
    MOBILE: 'Small',
    TABLET: 'Medium',
    LARGE: 'Large',
    DESKTOP: 'DESKTOP',
    XL: 'Xl',
};

export const VIEWPORT_MEDIA_QUERIES = {
    MOBILE: 'max-width: 767px',
    TABLET: 'min-width: 768px',
    DESKTOP: 'min-width: 1024px',
    XL: 'min-width: 1200px',
};

export const LOGIN_PAGE_URL = `${viomeAppUrl}/login`;

export const API_DOMAIN = apiDomain;

export const API_DOMAIN_V1 = process.env.NEXT_PUBLIC_API_DOMAIN_V1;

export const VDP_URL = process.env.NEXT_PUBLIC_VDP_URL;

export const APP_URL = appUrl;

export const SUPPORT_PAGE_URL = {
    register:
        'https://support.viome.com/en_us/cancerdetect-may-i-purchase-viome-as-a-gift-H1wcZOFa9',
    personalInformation:
        'https://support.viome.com/en_us/cancerdetect-multiple-kits-in-the-same-household-HyvP3PKaq',
    contactInformation:
        'https://support.viome.com/en_us/cancerdetect-multiple-kits-in-the-same-household-HyvP3PKaq',
    notEligiblePage: 'https://support.viome.com/en_us/cdot-about-BkVlgc59c',
};

export const USER_GENDER_QUESTION_ID = 6;

export const NEXT_API_FOLDER = 'cd-api';

export const LOCAL_STORAGE_CD_ELEGIBILITY = 'cancerDetectFormPersist';

export const ELIGIBILITY_DEFAULT_VALUES_NOT_REQUIRED = [
    'familyMembers',
    'apartmentSuitNo',
    'tobaccoPrevious',
    'tobaccoTypes',
    'tobaccoYears',
    'tobaccoDay',
];

export const ELIGIBILITY_QUESTIONAIRE_REQUIRED = [
    'reasonForTesting',
    'ethnicity',
    'familyCancerYouOrCloseFamilyMember',
    'familyCancerYou',
    'familyCancerYourFamily',
    'tobaccoCurrent',
    'hpv',
];

export const ELIGIBILITY_DEFAULT_VALUES = {
    email: '',
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    streetAddress: '',
    apartmentSuitNo: '',
    city: '',
    phoneNumberCountryCode: '',
    phoneNumber: '',
    country: '',
    state: '',
    zipCode: '',
    tobaccoCurrent: '',
    tobaccoPrevious: '',
    tobaccoTypes: '',
    tobaccoYears: '',
    tobaccoDay: '',
    hpv: '',
    reasonForTesting: '',
    ethnicity: [],
    familyCancerYouOrCloseFamilyMember: '',
    familyCancerYou: '',
    familyCancerYouAge: '',
    familyCancerYourFamily: '',
    familyMembers: [],
};

export const ELEGIBILITY_STEPS = {
    MAX_STEPS: 15,
    MAX_STEPS_QUESTIONNAIRE: 12,
    PERSONAL_INFO_STEP: 0,
    CONTACT_DETAILS_STEP: 1,
    TOBACCO_USE_STEP: 2,
    TOBACCO_USE_PREVIOUS_STEP_PREVIOUS: 3,
    TOBACCO_USE_TYPES: 4,
    TOBACCO_USE_YEARS: 5,
    TOBACCO_USE_DAY_CONSUME: 6,
    HPV_STEP: 7,
    PRIMARY_REASON_STEP: 8,
    ETHNICITY_STEP: 9,
    YOU_OR_FAMILY_CANCER: 10,
    YOU_CANCER: 11,
    YOU_CANCER_AGE: 12,
    YOUR_FAMILY_CANCER: 13,
    FAMILY_MEMBERS_STEP: 14,
    CONFIRMATION_DETAILS_STEP: 15,
    RESULTS_STEP_PRE_APPROVED: -3,
    RESULTS_STEP_NOT_ELIGIBLE: -4,
    REGISTER_STEP_ACKNOWLEDGE: -2,
    REGISTER_STEP_EMAIL: -1,
    DENTIST_INFO_STEP: -5,
};

export const REDIRECT_URL_ON_ERROR = 'https://www.viome.com/products';

export const ELIGIBILITY_ROOT_PATH = '/eligibility/register';

export const ELIGIBILITY_ROOT_PATH_DENTISTS =
    '/eligibility/register-for-dentists';

export const QUESTIONS_IDS = {
    REASON_FOR_TESTING: 11,
    REASON_FOR_TESTING_OTHER: 111, // ASK
    ETHNICITY: 12,
    YOU_OR_FAMILY_MEMBER_CANCER: 3,
    YOU_CANCER: 301,
    YOU_AGE_CANCER: 311,
    FAMILY_MEMBER_CANCER: 302,
    FAMILY_MEMBER_CANCER_LIST_NAME: 321,
    FAMILY_MEMBER_CANCER_LIST_AGE: 322,
    TOBACCO_USE: 4,
    TOBACCO_YEARS: 411,
    TOBACCO_TYPES: 412,
    TOBACCO_DAY: 413,
    HPV: 15,
};

export const ELIGIBILITY_PAGE_TITLE = {
    main: 'CD Eligibility',
    register: 'Register',
    personalDetails: 'Personal Details',
    contactDetails: 'Contact Details',
};

export const ELEGIBILITY_STEPS_PAGE_TITLES_AND_PATHS = {
    PERSONAL_INFO_STEP: {
        title: 'Personal Information',
        path: '/eligibility/personal-information',
    },
    CONTACT_DETAILS_STEP: {
        title: 'Contact Information',
        path: '/eligibility/contact-information',
    },
    TOBACCO_USE_STEP: {
        title: 'Current Tobacco Use',
        path: '/eligibility/current-tobacco-use',
    },
    TOBACCO_USE_PREVIOUS_STEP_PREVIOUS: {
        title: 'Previous Tobacco Use',
        path: '/eligibility/previous-tobacco-use',
    },
    TOBACCO_USE_TYPES: {
        title: 'Tobaacco Use Types',
        path: '/eligibility/tobaacco-use-types',
    },
    TOBACCO_USE_YEARS: {
        title: 'Tobaacco Use Years',
        path: '/eligibility/tobaacco-use-years',
    },
    TOBACCO_USE_DAY_CONSUME: {
        title: 'Tobaacco Use Day Consume',
        path: '/eligibility/tobaacco-use-day-consume',
    },
    HPV_STEP: {
        title: 'Personal and family history - HPV',
        path: '/eligibility/hpv',
    },
    PRIMARY_REASON_STEP: {
        title: 'Purchasing Reason',
        path: '/eligibility/purshasing-reason',
    },
    ETHNICITY_STEP: {
        title: 'Ethnicity',
        path: '/eligibility/ethnicity',
    },
    YOU_OR_FAMILY_CANCER: {
        title: 'Personal and Family History - Cancer',
        path: '/eligibility/cancer-you-or-family',
    },
    YOU_CANCER: {
        title: 'Personal and Family History - Cancer - You',
        path: '/eligibility/cancer-you',
    },
    YOU_CANCER_AGE: {
        title: 'Personal and Family History - Cancer - Age',
        path: '/eligibility/cancer-you-age',
    },
    YOUR_FAMILY_CANCER: {
        title: 'Personal and Family History - Cancer - Your Family',
        path: '/eligibility/cancer-your-family',
    },
    FAMILY_MEMBERS_STEP: {
        title: 'Personal and Family History - Cancer - Family Members',
        path: '/eligibility/cancer-family-members',
    },
    CONFIRMATION_DETAILS_STEP: {
        title: 'Confirmation Details',
        path: '/eligibility/confirmation-details',
    },
    MAX_STEPS: {
        title: 'Confirmation Details',
        path: '/eligibility/confirmation-details',
    },
    REGISTER_STEP_EMAIL: {
        title: 'Register - Email',
        path: '/eligibility/register',
    },
    REGISTER_STEP_ACKNOWLEDGE: {
        title: 'Register - Acknowledgement',
        path: '/eligibility/register',
    },
    RESULTS_STEP_NOT_ELIGIBLE: {
        title: 'Results - Not Eligible',
        path: '/eligibility/results-not-eligible',
    },
    RESULTS_STEP_PRE_APPROVED: {
        title: 'Results - Pre-Approved',
        path: '/eligibility/results-pre-approved',
    },
};

export const ELIGIBILITTY_GA_EVENTS = {
    STEP_PAGE_VIEW: 'eligibility_step_view',
    RESULT_PRE_APPROVED: 'eligibility_result_pre_approved',
    RESULT_NOT_ELIGIBLE: 'eligibility_result_not_eligible',
    CLICK_BUY: 'eligibility_click_buy',
    CLICK_ACKNOWLEDGE: 'eligibility_click_acknowledge',
    CLICK_CONFIRM: 'eligibility_click_confirm',
    CLICK_ORDER_THE_TEST_NOW: 'eligibility_click_order_the_test_now',
};

export const SCORE_BULLETS = {
    'Gut Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/3wp4l4WBOpMN2Uam91yLM7/dc3cf107ed8ea57cb871a600c9e4df14/Frame_257__2_.png',
    'Cellular & Energy Efficiency':
        'https://images.ctfassets.net/qk4l4jfatr3e/1fCA0DFPa2x6ILlcfUugEo/5536f617695b99ad9087fd46cf9e696d/Frame_256__4_.png',
    'Inflammation Response':
        'https://images.ctfassets.net/qk4l4jfatr3e/6NhNbpJ7PQeWjuN9IHLiw8/85b32e59f91076fb2fffc8d3168d2c14/Frame_256__3_.png',
    'Immune System Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/3lktr5o4zns8e6bwEAOjLE/eb42fc8976785dc44b03975052e5679d/Frame_257__1_.png',
    'Oral Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/1CzeAek15sRTxLMINuL1l2/fa84e65622fd932b5afab157cb2628c7/Frame_256__2_.png',
    'Brain & Cognitive Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/30NNCihJPVvN1ltHaUe1Dx/9ef58156c5436349adee4a756a4d4b77/Frame_256__1_.png',
    'Heart & Cardiometabolic Health':
        'https://images.ctfassets.net/qk4l4jfatr3e/25JqDHlKxtoqqVJdjIE2Xc/23634d1ae4491b22a01e49e819641ecc/Frame_256.png',
    'Biological Age':
        'https://images.ctfassets.net/qk4l4jfatr3e/78p4LzaeZkvMqcbWgQj9hq/ab1c6f8923277ae1dbfb3f47dfe55b23/Frame_257.png',
};

export const contentfulOptions = {
    CONTENTFUL_PREVIEW_ACCESS_TOKEN:
        process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
    CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
    isPreview: ENVIRONMENT === 'development' || ENVIRONMENT === 'preview',
    websiteSlug: 'cancer-detect-main',
    websiteUrl: 'https://cancerdetect.viome.com/',
    blogFolder: 'resources',
};

export const NEXT_PUBLIC_API_DOMAIN =
    ENVIRONMENT === 'production'
        ? 'https://my.viome.com/app'
        : 'https://test.viome.com/app';

export const RISK_ASSESSMENT_PAGE_TITLE = {
    main: 'Risk Assessment',
};

export const STATSIG_KEY = process.env.NEXT_PUBLIC_STATSIG_KEY;
export const STATSIG_ENV = process.env.NEXT_PUBLIC_STATSIG_ENV;
