// import { ENVIRONMENT } from 'utils/constants';
import { Statsig } from 'statsig-react';

// const isProd = ENVIRONMENT === 'production';

export default function sendEvent(event, value, metadata) {
    let eventMetadata = {},
        eventValue = value;

    if (event.includes('page_load')) {
        const searchParams = new URLSearchParams(value.search);
        eventValue = value.pathname;

        // Remove first forward slash if not homepage
        if (eventValue.length > 1) {
            eventValue = eventValue.startsWith('/')
                ? eventValue.substr(1)
                : eventValue;
        }

        eventMetadata = {
            slug: eventValue,
        };

        searchParams.forEach((parameterValue, parameterKey) => {
            if (parameterKey.includes('utm')) {
                eventMetadata[parameterKey] = parameterValue;
            }
        });
    } else {
        eventMetadata = metadata;
    }

    Statsig.logEvent(event, eventValue, eventMetadata);
}
