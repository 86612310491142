export const getUserSuscriptions = async (email) => {
    try {
        const hasSubscription = await fetch(`/api/subscriptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then((response) => response.json())
            .then((data) => data)
            .catch((error) => {
                console.error({ error });
            });

        return hasSubscription;
    } catch (error) {
        return { error: true };
    }
};

export default getUserSuscriptions;
