import { StatsigClient } from '@statsig/js-client';
import { STATSIG_KEY, STATSIG_ENV } from './constants';

const statsigClient = new StatsigClient(
    STATSIG_KEY,
    { userID: 'a-user' },
    { environment: { tier: STATSIG_ENV } },
);

statsigClient.initializeSync();

export default statsigClient;
