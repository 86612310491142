import { NEXT_API_FOLDER } from 'utils/constants';

export const getUserCountry = async (ip) => {
    const params = new URLSearchParams({
        ip,
    });
    try {
        const results = await fetch(`/${NEXT_API_FOLDER}/country?${params}`)
            .then((response) => {
                if (response.status !== 200) {
                    return { error: true };
                }
                return response.json();
            })
            .then((res) => {
                if (res.error) {
                    return { error: true };
                }
                return res;
            });
        return results;
    } catch (error) {
        return { error: true };
    }
};

export default getUserCountry;
